.main-map{
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    height: 500px;
    width: 100%;
    overflow: hidden;
}

@media(max-width: 776px){
    .main-map{
        height: 350px;
    }
}