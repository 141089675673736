.modal-content{
    border-radius: 16px;
    background: #FFF;
    border: none;
}

.product-modal{
    padding: 0 !important;
    .modal-dialog{
        max-width: 974px;
    }
    .modal-content{
        padding: 0;
    }
    .modal-body{
        padding: 0;
    }
}
.product-modal-hidden{
    max-height: 429px;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 6px;
    &::-webkit-scrollbar {
        width: 5px;
        opacity: 0;
        position: absolute;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
}
.close{
    position: absolute;
    color: #fff;
    right: -44px;
    font-size: 30px;
    cursor: pointer;
    transition: .3s;
    z-index: 1000;

    &:hover{
        transform: scale(1.1);
    }
}
.product-features{
    border-top: 1px solid #DCDCDC;
    border-bottom: 1px solid #DCDCDC;
    padding: 8px 24px;
    margin: 0 -24px 16px;
    display: flex;
    justify-content: space-between;
}
.product-features-item{
    color: rgba(35, 35, 35, 0.60);
    .value{
        font-size: 16px;
        font-weight: 500;
        margin-top: 4px;
    }
}
.product-modal-wrapper{
    display: grid;
    grid-template-columns: 1fr 482px;

    .product-heart{
        position: absolute;
        left: 12px;
        top: 12px;
        z-index: 3;
        background: #fff;
        width: 40px;
        height: 40px;
        color: #333333;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        cursor: pointer;

        i{
            transition: .3s;
        }

        .icon-like{
            color: red;
        }

        &:hover{
            i{
                transform: scale(1.1);
                color: red;
            }
        }
    }

    .subtitle{
        font-size: 12px;
    }

    img{
        width: 100%;
        height: 100%;
        min-height: 320px;
        object-fit: cover;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
    }
    h2{
        font-size: 24px;
        font-weight: 600;
    }
}
.product-modal-content{
    padding: 24px 20px 24px 32px;
    display: flex;
    flex-direction: column;
    position: relative;

    span{
        color: var(--mainColor);
    }
    .availability{
        color: #30475E;
        margin: 12px 0;
        font-size: 16px;
    }
    .content{
        font-size: 16px;
        margin-bottom: 16px;
        margin-top: 6px;
    }
    .main-btn{
        width: 100%;
        margin-top: auto;
    }
    .flex{
        align-items: center;
    }
}

.product-input{
    margin-bottom: 24px;
}
.product-label{
    display: flex;
    margin: 3px;

    &:not(:last-child){
        margin-bottom: 14px;
    }
    input{
        position: absolute;
        pointer-events: none;
        opacity: 0;
    }

    span{
        font-size: 14px;
        color: rgba(35, 35, 35, 0.60);
        position: relative;
        padding-left: 30px;
        display: flex;
        align-items: center;

        &:before{
            content: "";
            position: absolute;
            width: 22px;
            height: 22px;
            background: #DDDDDD;
            border-radius: 50%;
            display: flex;
            left: 0;
        }
    }
}
.product-label input:checked ~ span {
    -webkit-transition-duration: 0.08s, 0.08s;
            transition-duration: 0.08s, 0.08s;

    &:before{
        content: "";
        background: var(--mainColor);
        border: 4px solid #DDDDDD;
    }       
}
.product-label input:disabled ~ span {
    cursor: default;
    opacity: 0.5;
}
.product-title{
    color: #232323;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 14px;
}
.modal-states{
    position: absolute;
    display: flex;
    gap: 8px;
    bottom: 16px;
    left: 16px;
    flex-wrap: wrap;

}
.product-bonus{
    background-color: var(--bonusColor);
    color: #fff;
    border-radius: 20px;
    padding: 4px 12px;
    width: fit-content;
    font-size: 12px;
}

.product-checkbox{
    margin: 3px;
    display: flex;
    &:not(:last-child){
        margin-bottom: 14px;
    }
    input{
        position: absolute;
        pointer-events: none;
        opacity: 0;
    }

    span{
        position: relative;
        padding-left: 30px;
        display: flex;
        align-items: center;
        color: rgba(35, 35, 35, 0.6);
        &::before{
            content: "";
            position: absolute;
            width: 20px;
            height: 20px;
            background: #DDDDDD;
            border-radius: 4px;
            left: 0;
        }
    }
    input:checked ~ span{
        &::before{
            background: var(--mainColor);
        }
        &::after{
            content: "\e92a";
            font-family: 'icomoon' !important;
            position: absolute;
            left: 0;
            font-size: 20px;
            color: #fff;
        }
    }
}
.product-reviews-title{
    color: #426AF8;
    font-size: 16px;
    margin-top: 4px;
    cursor: pointer;
}
.product-reviews{
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    width: 100%;
    height: 100%;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    opacity: 0;
    visibility: hidden;

    &.show{
        opacity: 1;
        visibility: visible;
    }

    .product-reviews-button{
        position: absolute;
        bottom: 10px;
        width: calc(100% - 48px);
        left: 24px;
    }

    &-top{
        padding: 24px 20px 16px 32px;

        &-title{
            display: flex;
            align-items: center;
            gap: 4px;
            color: #426AF8;
            cursor: pointer;
        }
    }
    &-bottom{
        background: #F3F3F3;
        padding: 16px 8px 16px 24px;
        height: calc(100% - 111px);
        overflow-y: auto;
    }
    &-item{
        background: #fff;
        padding: 16px;
        border-radius: 8px;
        margin-bottom: 10px;

        &:last-child{
            margin-bottom: 50px;
        }

        hr{
            color: #ECECEC;
        }

        &-flex{
            display: flex;
            justify-content: space-between;
        }
        &-date{
            font-size: 12px;
            color: #474747;
            margin: 4px 0;
        }
        p{
            font-size: 16px;
            color: #090909;
            margin-bottom: 10px;
        }
        &-images{
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            margin-bottom: 10px;
            img{
                width: 54px;
                height: 54px;
                border-radius: 4px;
                min-height: 54px;
            }
        }
    }
}
.review-reply{
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;

    &-top{
        display: flex;
        justify-content: space-between;
        
        span{
            font-size: 12px;
            color: #474747;
        }
    }
    &-user{
        font-weight: 500;
        margin: 6px 0;
    }
}
.list-buttons{
    display: flex;
    gap: 10px;
    margin-top: 10px;
    justify-content: end;

    .main-btn{
        width: auto;
    }
}
.reply-button{
    color: #426AF8;
    text-align: right;
    margin-bottom: 10px;
    cursor: pointer;
}
.rating {
    display: flex;
    gap: 4px;
    li {
        .feather {
            fill: #ffb016;
            stroke: #ffb016;
        }
    }
}
.upload-img{
    position: relative;
    background: #D5D5D5;
    border-radius: 16px;
    height: 230px;
    width: 100%;

    &.mini{
        height: 130px;
    }
    &-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        height: 100%;
        gap: 10px;
        i{
            font-size: 40px;
        }
    }
    input{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;

    }

    &:hover{
        span{
            visibility: visible;
            opacity: 1;
        }
        .upload-img-content{
            img{
                opacity: .5;
            }
            &::after{
                opacity: 1;
                visibility: visible
            }
        }
        
    }

    &-content{
        position: relative;
        transition: .3s;
        &::after{
            position: absolute;
            content: "\f019";
            font-family: 'Font Awesome 6 Free' !important;
            left: 48%;
            top: 40%;
            font-size: 34px;
            opacity: 0;
            visibility: hidden;
            transition: .3s;

        }
        span{
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 24px;
            opacity: 0;
            visibility: hidden;
            transition: .3s;
            cursor: pointer;
            z-index: 11;
        }
        img{
            height: 230px;
            border-radius: 16px;
            width: 100%;
            object-fit: cover;
        }
    }
}
.review-shadow{
    .modal-content{
        box-shadow: rgba(6, 5, 50, 0.1) 0px 6px 20px;
    }
}
.reviews-images{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    img{
        width: 100px;
        height: 100px;
        object-fit: cover;
    }
}
//address-modal

.address-modal{
    .inputs-grid{
        display: flex;
        flex-wrap: wrap;

        .input{
            width: calc(50% - 12px);
            margin-bottom: 12px;
            &:nth-child(1n){
                margin-right: 6px;
            }
            &:nth-child(2n){
                margin-left: 6px;
            }
        }
    }
    .modal-dialog {
        max-width: 850px;
    }
    .modal-body{
        padding: 0;
    }
    h3{
        margin-bottom: 20px;
    }
    .input{
        margin-bottom: 16px;
    }
    &.no-map{
        .modal-dialog {
            max-width: 450px;

            .address-modal-wrapper{
                grid-template-columns: 1fr;
                height: 500px;
            }
        }
    }
}
.address-modal-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.address-modal-container{
    padding: 24px;
    display: flex;
    flex-direction: column;

    .main-btn{
        width: 100%;
        margin-top: auto;
    }

    .mr-0{
        margin-top: 8px;
    }

    h2{
        margin-bottom: 16px;
    }
}
.pickup-item{
    border-radius: 8px;
    background: #F9F9F9;
    padding: 12px 16px;
    border: 1px solid #F9F9F9;
    position: relative;

    &-title{
        color:#232323;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 4px;
    }
    p{
        color: #66656B;
        font-size: 12px;
    }

    &.active{
        border: 1px solid var(--mainColor);
    }

    .delivery-card-input{
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        top: 0;
        left: 0;
        cursor: pointer;
    }
}
.address-modal-top-item{
    position: relative;
}
.delivery-check-input{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.address-pin{
    font-weight: 600;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    z-index: 10000;
    right: 10px;
    top: 10px;
    background: rgba(255, 255, 255, 0.60);
    width: 44px;
    height: 44px;
    border-radius: 50%;
    justify-content: center;
    transition: .3s;

    &:hover{
        background: rgba(255, 255, 255, 1);
    }
    i{
        font-size: 22px;
        color: #000000;
    }
}
.address-modal-top{
    border-radius: 12px;
    background: #F9F9F9;
    padding: 7px 8px;
    display: grid;
    gap: 8px 16px;
    margin-bottom: 32px;
    grid-template-columns: 1fr 1fr;

    &-item{
        background: #FFF;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 16px;
        color: var(--mainColor);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .3s;
        white-space: nowrap;
        &.active{
            color: #fff;
            background: var(--mainColor);
        }
    }
}
//delete-profile-modal

.delete-profile-modal{
    padding: 30px;
    background: #fff;
    border-radius: 16px;
    text-align: center;

    h3{
        margin-bottom: 30px;
    }

    .main-btn{
        width: 100%;
    }

    img{
        max-width: 200px;
        max-height: 200px;
        object-fit: contain;
        margin-bottom: 16px;
    }
}

//delivery-point-modal
.delivery-point-card{
    border-radius: 16px;
    border: 2px solid #E0E3E7;
    background: #FFF;
    padding: 12px;
    width: 100%;
    margin-bottom: 10px;

    .card-item{
        display: flex;
        font-size: 16px;
        font-weight: 500;
        color: #5D6167;
        margin-bottom: 8px;

        span{
            color: #9CA5AF;
            font-size: 14px;
        }
    }
}
.max-height-address{
    max-height: 286px;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 5px;
        opacity: 0;
        position: absolute;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }
}
.login-modal-container, .delete-modal{
    .modal-content{
        border: none;
    }
    .modal-dialog{
        max-width: 448px;   
    }
    .modal-body{
        padding: 0;
    }
    .styles_main__-0FEu{
        border-radius: 16px;
    }
    .Image-styles_image__gnfW1{
        border-radius: 16px;
    }
}
.delivery-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .delivery-wrapper-content{
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fff;
        display: flex;
        flex-direction: column;
        z-index: 4;
        .main-btn{
            margin-top: 0;
        }
        .btn-gray{
            margin-top: auto;
            margin-bottom: 16px;
        }
    }
}
.mob-visible{
    display: none;
}

@media(max-width: 767px){
    .mob-visible{
        display: flex;
    }
    .product-modal-hidden{
        max-height: none;
        overflow-y: auto;
    }
    .address-modal-top-item{
        font-size: 14px;
    }
}