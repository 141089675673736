.categories-list{
    display: none;
    overflow-x: auto;
    gap: 8px;
    margin-bottom: 30px;
    padding-bottom: 4px;

    .logo-img{
        width: 40px;
        object-fit: contain;
        margin-left: 10px;
        display: none;
    }

    &::-webkit-scrollbar {
        height: 0;
        opacity: 0;
        position: absolute;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        box-shadow: 0 0 2px rgba(0, 0, 0, .2) inset;
    }
    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;
        border: 1px solid #f1f1f1;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #000000;
    }

    .category-card{
        padding: 8px 20px;
        background: #E0E3E7;
        border-radius: 30px;
        white-space: nowrap;
        transition: .3s;

        &.active{
            background: var(--mainGradient);
            color: #fff;
        }
        span{
            transition: 0;
        }
        .icon-like{
            color: #fff;
            margin-right: 4px;
            font-size: 14px;
        }
    }
}
.logo-img{
    display: none;
}
.sticky{
    position: fixed;
    top: 0;
    z-index: 10;
    padding: 4px 0 0;
    left: 0;
    width: 100vw;
    background: #fff;
    .categories-list{
        margin-bottom: 0;
        .logo-img{
            display: block;
        }
    }
    .logo-img{
        display: block;
        width: 100px;
        max-width: 150px;
        max-height: 50px;
        margin: 0 auto 4px;
        object-fit: contain;
    }
}